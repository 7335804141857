import React, { useState, useEffect } from 'react';
import './Main.css';
import TabButtons from './components/TabButtons';
import FAQ from './components/FAQ';
import History from './components/History';
import Buypremium from './components/Buy';
import PrivacyPolicy from './components/PrivacyPolicy';
import GoogleButton from './components/GoogleAuth';
import Generatepage from "./components/Generatepage";
import HistoryPreview from "./components/HistoryPreview";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Popup from './components/Popup';
import ReactGA from 'react-ga4';
import { analytics } from './components/firebase'; 
import { logEvent } from 'firebase/analytics'; 
import { config } from '../config'; 
import { useRef } from 'react';


function App() {
    const [activeTab, setActiveTab] = useState('write');    
    const [isOpen, setIsOpen] = useState(false);
    const [showPremium, setShowPremium] = useState(false);
    const [showHistoryPreview, setShowHistoryPreview] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [result, setResult] = useState('');
    const [showGenerationPage, setShowGenerationPage] = useState(false);
    const [Topic, setTopic] = useState('');
    const [keyPoints, setKeyPoints] = useState('');
    const [SupportiveInput, setSupportiveInput] = useState('');
    const [language, setLanguage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('userProfile')));
    const [errors, setErrors] = useState({}); // Error state for validation
    const subscriptionStatus = JSON.parse(localStorage.getItem('subscriptionStatus'));
    const [popupMessage, setPopupMessage] = useState('');
    const [isPopupVisible, setPopupVisible] = useState(false);
    const googleanalytics  = process.env.REACT_APP_GOOGLE_ANALYITCS_ID;
    const [activeButton, setActiveButton] = useState('home');
    const [activeSliderButton, setActiveSliderButton] = useState('home');
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const imageSrc = config.upgradetopro;
    const premiumButtonRef = useRef(null);
    React.useEffect(() => {
       
        document.documentElement.style.setProperty('--main-color', config.color);
    }, [config.color]);
    
    useEffect(() => {
        ReactGA.initialize(googleanalytics); // Replace with your tracking ID
        ReactGA.send('pageview_paragraph'); // Track initial page view
    }, []);
    useEffect(() => {
        if (analytics) {
          logEvent(analytics, 'app_opened_paragraph');
        } else {
          console.warn('Analytics is not initialized.');
        }
      }, []);

      useEffect(() => {
        const savedState = localStorage.getItem('showComponent');
        if (savedState === 'true') {
            setShowPremium(true);
            localStorage.removeItem('showComponent');
        }
      }, []);
  

   
    const toggleOverlay = () => setIsOpen((prev) => !prev);
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.get('subscription_updated')) {
            toast('You have successfully subscribed to the plan! Enjoy your benefits!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setTimeout(() => {
                params.delete('subscription_updated');
                window.history.replaceState({}, document.title, window.location.pathname + '?' + params.toString());
            }, 3000);   
        }
    }, []);

    useEffect(() => {
        const userProfile = JSON.parse(localStorage.getItem('userProfile')); // Get the user profile from local storage
        const userEmail = userProfile?.email; // Extract the email from the user profile
        if (userEmail) {
            checkSubscription(userEmail); // Check subscription on load
        } else {
           
        }
    }, []);
    
    const checkSubscription = async (userEmail) => {
        try {
            const response = await fetch(`${config.baseURL}/check_subscription.php?user_email=${encodeURIComponent(userEmail)}`);
            const rawResponse = await response.text(); // Get the raw response
            
           

            const data = JSON.parse(rawResponse); // Attempt to parse it as JSON

            if (data.error) {
                
            } else {
                // Save subscription status and plan ID to local storage
                localStorage.setItem('subscriptionStatus', JSON.stringify(data.subscription_status));
                localStorage.setItem('planId', JSON.stringify(data.plan_id)); // Save plan_id to local storage
            }
        } catch (error) {
            console.error('Error fetching subscription:', error);
        }
    };

    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    const handleHomeClick = () => {
        setShowPremium(false);
        setIsButtonVisible(true);
        setShowPrivacyPolicy(false);
        setShowGenerationPage(false);
        setShowHistoryPreview(false);
        setTopic('');
        setKeyPoints('');
        setSupportiveInput('');
        setLanguage('');
        setActiveTab('write'); 
        logEvent(analytics, 'Home_click_paragraph', {
            button_name: 'home_button',
        });// Show main page content
    };


    const handleBuyPremiumClick = () => {
        setShowPremium(true);
        setIsButtonVisible(false);
        setShowPrivacyPolicy(false);
        setShowGenerationPage(false);
        setShowHistoryPreview(false);
        setTopic('');
        setKeyPoints('');
        setSupportiveInput('');
        setLanguage('');
        logEvent(analytics, '3 Days Trial_click_paragraph', {
            button_name: 'Buy_premium',
        });
    };

    const handlePrivacyPolicyClick = () => {
        setShowPremium(false);
        setIsButtonVisible(false);
        setShowPrivacyPolicy(true);
        setShowGenerationPage(false);
        setShowHistoryPreview(false);
        setTopic('');
        setKeyPoints('');
        setSupportiveInput('');
        setLanguage('');
        logEvent(analytics, 'Privacy_click_paragraph', {
            button_name: 'Privacy_page',
        });
    };
    const handleGenerate = async () => {
        setErrors({});
        let hasError = false;
        logEvent(analytics, 'Generated_paragraph', {
            button_name: 'Generate_button_paragraph',
           
        });
        // Validation checks
        if (!Topic) {
            setErrors(prev => ({ ...prev, Topic: 'Topic is required.' }));
            hasError = true;
            setTimeout(() => {
                setErrors(prevErrors => {
                    const updatedErrors = { ...prevErrors };
                    delete updatedErrors.Topic;
                    return updatedErrors;
                });
            }, 3000);
        }
    
        if (hasError) return; // Exit if there are validation errors
    
        setIsLoading(true);
        setIsButtonVisible(false);
        document.body.classList.add('no-scroll');
        
        try {
            let currentClickCount = 0;
            let maxClicks = 5; // Default for non-pro users
            let isPro = false; // Default for non-logged-in users
    
            if (user && user.email) {
                // User is logged in
                const response = await fetch(`${config.baseURL}/click_count.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email: user.email }),
                });
    
                const clickData = await response.json();
                if (clickData) {
                    if (clickData.error) {
                        alert(clickData.error);
                        return;
                    }
                    currentClickCount = clickData.click_count;
                    isPro = clickData.is_pro === 'yes';
                    maxClicks = isPro ? 25 : 5; // Set max clicks based on user type
                }
            } else {
                // User is not logged in, use session storage
                const storedCount = JSON.parse(localStorage.getItem('clickCount')) || {};
                currentClickCount = storedCount.count || 0;
    
                const lastLocalDate = new Date(storedCount.lastClickDate);
                const now = new Date();
                if (now - lastLocalDate > 24 * 60 * 60 * 1000) {
                    // Reset local count if older than a day
                    currentClickCount = 0;
                    localStorage.setItem('clickCount', JSON.stringify({ count: 0, lastClickDate: now.toISOString() }));
                }
            }
    
            // Check click limits
            currentClickCount = Math.floor(currentClickCount);
            maxClicks = Math.floor(maxClicks);
    
            if (currentClickCount >= maxClicks) {
                setPopupMessage('Your Daily Limit Reached. Buy Premium to Continue.');
                setShowPremium(true);
                setIsButtonVisible(false);
                setPopupVisible(true);
                setTimeout(() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                }, 0);
                return; // Stop further processing
            }
    
            // Proceed with generation
            const requestBody = {
                Topic: Topic,
                keyPoints: keyPoints,
                SupportiveInput: SupportiveInput,
                selectedLanguage: language,
                ID: user ? user.id : null,
            };
    
            // Call the document generation API
            const generationResponse = await fetch(`${config.baseURL}/openai_api.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
    
            const rawResponse = await generationResponse.text();  // Raw response as text
         
    
            let data;
            try {
                data = JSON.parse(rawResponse);  // Try to parse it as JSON
            } catch (error) {
                alert("Received an invalid response from the server. Please try again.");
                return;
            }
    
            // Check if the response structure is correct
            if (data.success && data.resultText) {
                const resultText = data.resultText;
    
                // Set the result and navigate to the generation page
                setResult(resultText);
                setIsButtonVisible(false);
                setShowGenerationPage(true);
                setTopic('');
                setKeyPoints('');
                setSupportiveInput('');
                setLanguage('');
    
            } else {
                alert("Error: Unexpected response from the API. Missing 'resultText'.");
                logEvent(analytics, 'Failed_paragraph', {
                    button_name: 'generated_paragraph',
                });
            }
    
            // Update click count
            currentClickCount += 1;
            if (user && user.email) {
                localStorage.setItem('clickCount', JSON.stringify({ count: currentClickCount, lastClickDate: new Date().toISOString() }));
            } else {
                localStorage.setItem('clickCount', JSON.stringify({ count: currentClickCount, lastClickDate: new Date().toISOString() }));
            }
    
        } catch (error) {
            alert("An error occurred while generating the document.");
            console.error(error);  // Log the error for debugging purposes
        } finally {
            setIsLoading(false);
            document.body.classList.remove('no-scroll');
        }
    };
    
    
    
    const handleSliderButtonClick = (callback) => {
        toggleOverlay(); // Close slider
        callback();
        logEvent(analytics, 'Slider_clicked_paragraph', {
            button_name: 'Clicked_slider_paragraph',
        }); // Call the passed callback function
    };

    const handleBackToMain = () => {
        setShowPrivacyPolicy(false); 
        setShowGenerationPage(false);
        setShowPremium(false);
        setShowHistoryPreview(false);
        setIsButtonVisible(true);// Hide the privacy policy
    };

    const handleLoginSuccess = (userProfile) => {
        if (userProfile) {
          ;
            setUser(userProfile);
            toast('Login successful.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover:false ,
                draggable: true,
                progress: undefined,
                theme: "light",
                
                });
                logEvent(analytics, 'Login_click_paragraph', {
                    button_name: 'login_button_paragraph',
                });
                checkSubscription(userProfile.email);
                
                
        } else {
           
            
        }
        
    };

    const handleLoginFailure = (error) => {
      
        if (error.error) {
            alert(`Login failed: ${error.error}`);
            logEvent(analytics, 'Loging_Failed_paragraph', {
                button_name: 'Login_failed_paragraph',
            });
        } else {
            
        }
    };

    const handleLogout = () => {
      localStorage.removeItem('userProfile');
        localStorage.removeItem('user');
        localStorage.removeItem('subscriptionStatus');
        sessionStorage.removeItem('userProfile'); 
        sessionStorage.removeItem('subscriptionStatus'); 
        localStorage.removeItem('rzp_checkout_anon_ids');
        localStorage.removeItem('rzp_checkout_anon_id');
        localStorage.removeItem('rzp_device_id');
        localStorage.removeItem('subscribedPlan');
        localStorage.removeItem('selectedPlan');
        localStorage.removeItem('planId');
        setUser(null);
        window.location.reload();
        logEvent(analytics, 'Logout_click_paragraph', {
            button_name: 'logout_button',
        });
        
        toast('Logout successful!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover:false ,
            draggable: true,
            progress: undefined,
            theme: "light",
            
            });
    };

    const handleDocumentSelect = (generatedResult) => {
        setResult(generatedResult);
        setShowHistoryPreview(true);
    };
    const [status, setStatus] = useState(null);
    const [error, setError] = useState(null);

    
        
    const closePopup = () => {
        setPopupVisible(false);
      };


      
    
    return (
       
        <div className="App">
            <ToastContainer />
            <div className='header_mobile'>
                <img
                    src={config.hamburger}
                    alt="Menu"
                    className="hamburger"
                    onClick={toggleOverlay}
                />
                <div className='login_crown_container'>
                    <img src={config.crown} alt="Crown" onClick={handleBuyPremiumClick} />
                
                    {user ? (
                                <button id="logout_header" onClick={handleLogout}>Logout</button>
                            ) : (
                                <GoogleButton id="logout_header" onLoginSuccess={handleLoginSuccess} onLoginFailure={handleLoginFailure} />
                            )}                           
                </div>
            </div>
            {(subscriptionStatus !== 'ACTIVE' || !subscriptionStatus) && isButtonVisible && (
  <div className="floating-button" onClick={handleBuyPremiumClick}>
    <img src={imageSrc} alt="Floating Button" />
  </div>
)}
            {isOpen && (
                <div className="overlay" onClick={toggleOverlay}>
                    <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
                        <div className='topslider'>
                            <div className="close-icon" onClick={toggleOverlay}>&#10005;</div>
                            <img src={config.frame} alt="Frame" className='frame' />
                          
                        </div>
                        <div className="button-container-slider">
                            <button className='buy-premium-slider' onClick={() => handleSliderButtonClick(handleBuyPremiumClick)}>
                                <img src={config.crown} alt="Crown" className='slidercrown' /> 3 Days Free Trial
                            </button>
                            <div className='buttonscrollonslider'>
                                <button className={`Home_btn_slider ${activeSliderButton === 'home' ? 'active' : ''}`} 
                                onClick={() => {
                                    handleSliderButtonClick(handleHomeClick);
                                    setActiveSliderButton('home'); // Set active state
                                }}>
                                    <span><img src={config.home} alt="Home" className='slider-Homeimg' /></span> Home
                                </button>
                                <button className={`Home_btn_slider ${activeSliderButton === 'privacy' ? 'active' : ''}`}
                                 onClick={() => {
                                    handleSliderButtonClick(handlePrivacyPolicyClick);
                                    setActiveSliderButton('privacy'); // Set active state
                                }}>
                                    <span><img src={config.privacy} alt="Privacy" className='slider-Homeimg' /></span> Privacy Policy
                                </button>
                            </div>
                            {user ? (
                                <button id="login_slider" onClick={handleLogout}>Logout</button>
                            ) : (
                                <GoogleButton id="login_slider" onLoginSuccess={handleLoginSuccess} onLoginFailure={handleLoginFailure} />
                            )}
                        </div>
                    </div>
                </div>
            )}

            <div id='container'>
                <div className="left_panel">
                    <img src={config.logo} alt="Logo" className='logo_left_panel' />
                    <h1 className="left_title">{config.title}</h1>
                      {/*  <img src={config.buyP} alt="Buy Premium" className='buy-btn-img-left-panel' onClick={handleBuyPremiumClick} /> */}
                      <button className='buy-btn-img-left-panel' onClick={handleBuyPremiumClick} ><img src={config.crown} alt="Crown"  className='panelcrown' />3 Days Free Trial </button>

                     <div className='btn-scroll'> 
                        <button className={`scroll_btn ${activeButton === 'home' ? 'active' : ''}`} onClick={() => {
            handleHomeClick();
            setActiveButton('home');
        }}>
                        <span><img src={config.homelp} alt="Home" className='scroll-img' /></span> Home
                    </button>
                    <button className={`scroll_btn ${activeButton === 'privacy' ? 'active' : ''}`} onClick={() => {
            handlePrivacyPolicyClick();
            setActiveButton('privacy');
        }}>
                        <span><img src={config.privacylp} alt="Privacy" className='scroll-img' /></span> Privacy Policy
                    </button></div>
                    {user ? (
                        <button id="login_left" onClick={handleLogout}>Logout</button>
                    ) : (
                        <GoogleButton onLoginSuccess={handleLoginSuccess} id="login_left" onLoginFailure={handleLoginFailure} />
                    )}
                </div>

                <div className={`main_panel ${isLoading ? 'loading' : showGenerationPage ? 'generation' : showPremium ? 'premium' : showPrivacyPolicy ? 'privacy' : 'main'}`}>
                {isPopupVisible && <Popup message={popupMessage} onClose={closePopup}  />}
                 {isLoading ? (
                          <>
                          <div className="full-screen-overlay"><div className="loader"></div></div>
                          
                      </>
                        
                    ) : showGenerationPage ? (
                        <Generatepage result={result} onBack={handleBackToMain} />
                    ) : showPremium ? (
                        <Buypremium onBack={() => {
                            handleBackToMain();
                            setActiveButton('home');
                            setActiveSliderButton('home');
                        }} />
                    ) : showHistoryPreview ? (
                        <HistoryPreview result={result} onBack={handleBackToMain}/>
                    ) : showPrivacyPolicy ? (
                        <PrivacyPolicy onBack={() => {
                            handleBackToMain();
                            setActiveButton('home');
                            setActiveSliderButton('home');
                        }}/>
                    ) : (
                        <div className="main_page">
                            <div className='title_main_page'>
                                <h1 className='title_name'>{config.title}  {subscriptionStatus === 'ACTIVE' && (
            <img src={config.proicon} alt="" className='proicon' />
        )} </h1>
                                <p className='username_text'>
                                    {user ? (
                                        <span>Welcome, {user.name}.</span>
                                    ) : (
                                        <span>Please log in to view your history.</span>
                                    )}
                                </p>
                            </div>

                            <TabButtons activeTab={activeTab} onTabChange={setActiveTab}  analytics={analytics}  />

                            <div className="form">
                                {activeTab === 'write' && (
                                    <div>
                                        <div className="topic">{config.Topic}</div>
                                        <textarea
                                            className={`input_1 ${errors.Topic ? 'error' : ''}`} // Light red border for error
                                            placeholder={config.Topicplaceholder}
                                            value={Topic}
                                            onChange={(e) => setTopic(e.target.value)}
                                            maxlength="1000"
                                        ></textarea>
                                        {errors.Topic && <div className="error-message">{errors.Topic}</div>} {/* Error message */}

                                        
                                          {/*  <div>
                                                <div className="topic">{config.Keypoints}</div>                            
                                                <textarea  type="text"
                                                    className="input_1" // Light red border for error
                                                    placeholder={config.Keypointsplaceholder}
                                                    value={keyPoints}
                                                    onChange={(e) => setKeyPoints(e.target.value)} > </textarea>                                                
                                            </div> */}
                                            
                                            <div>
                                                <div className="topic">{config.Keypoints}</div>                     
                        
                                                <select
                                                    className={`input_3 `}  // Light red border for error
                                                    name="tone"
                                                    id="language"
                                                    value={keyPoints}
                                                    onChange={(e) => setKeyPoints(e.target.value)}
                                                >
                                                    <option value="Formal">Formal</option>
                                                    <option value="Informal">Informal</option>
                                                    <option value="Friendly">Friendly</option>
                                                    <option value="Funny">Funny</option>
                                                    <option value="Convincing">Convincing</option>
                                                    <option value="Luxury">Luxury</option>
                                                    <option value="Relaxed">Relaxed</option>
                                                    <option value="Professional">Professional</option>
                                                    <option value="Witty">Witty</option>
                                                    <option value="Adventurous">Adventurous</option>
                                                    <option value="Persuasive">Persuasive</option>
                                                    <option value="Bold">Bold</option>
                                                    <option value="Empathetic">Empathetic</option>
                                                    <option value="Other">Other</option>

                                                </select>                                                                   
                                            
                                            </div>

                                            <div>
                                                <div className="topic">{config.SupportiveInput}</div>                     
                        
                                                <select
                                                    className={`input_3 `}  // Light red border for error
                                                    name="length"
                                                    id="language"
                                                    value={SupportiveInput}
                                                    onChange={(e) => setSupportiveInput(e.target.value)}
                                                >
                                                    <option value="Formal">Short</option>
                                                    <option value="Informal">Medium</option>
                                                    <option value="Friendly">Long</option>
                                            
                                                </select>                                                                   
                                            
                                            </div>


                                            <div>
                                                <div className="topic">Language</div>
                                                <select
                                                    className="input_3"  // Light red border for error
                                                    name="language"
                                                    id="language"
                                                    value={language}
                                                    onChange={(e) => setLanguage(e.target.value)}
                                                >

                                                    <option value="English">English</option>
                                                    <option value="Arabic">Arabic (RTL)</option>
                                                    <option value="Bulgarian">Bulgarian</option>
                                                    <option value="Chinese">Chinese (Simplified)</option>
                                                    <option value="French">French</option>
                                                    <option value="German">German</option>
                                                    <option value="Hebrew">Hebrew (RTL)</option>
                                                    <option value="Hindi">Hindi</option>
                                                    <option value="Indonesian">Indonesian</option>
                                                    <option value="Italian">Italian</option>
                                                    <option value="Japanese">Japanese</option>
                                                    <option value="Portuguese">Portuguese</option>
                                                    <option value="Punjabi">Punjabi</option>
                                                    <option value="Russian">Russian</option>
                                                    <option value="Spanish">Spanish</option>
                                                    <option value="Swahili">Swahili</option>
                                                    <option value="Tagalog">Tagalog</option>
                                                    <option value="Urdu">Urdu</option>
                                                    <option value="Other">Other</option>
                                                </select>                            
                                            </div>
                                           
                                       
                                        <img
                                            className='generate_btn'
                                            src={config.generate}
                                            alt="Generate"
                                            onClick={handleGenerate}
                                        />
                                    </div>
                                )}
                                {activeTab === 'history' && <History onDocumentSelect={handleDocumentSelect} />}
                            </div>
                        </div>
                    )}
                    
                </div>
            </div>

            
            {!showGenerationPage && <FAQ />}
        </div>
    );
}

export default App;