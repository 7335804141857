export const config = {
  title: 'AI Paragraph Writer',
  Writetab: "Write Paragraph",

  Topic : 'Topic',
  Topicplaceholder:'Write Paragraph topic here..',
  Keypoints : 'Select Tone',
  Keypointsplaceholder:'',
  SupportiveInput: 'Select Length',
  //color: '#FF3A12',
 
 color:'#1EBCED',
  getacceseline1: 'AI Advanced Paragraph Writing Tool',
  getacceseline2: ' Unlimited Paragraph Writing & Rewriting',
  getacceseline3: ' Rewriting & Article Writing Tool',
  getacceseline4: ' Ads-Free Experience',




  //color used across web 
  primaryColor: '#1EBCED',
  tabsColor: '#1EBCED',


  //images used accross web
  logo: require('./assets/vector.svg').default,
  buyP: require('./assets/Group 1437252828.svg').default,
  home: require('./assets/filled.svg').default,
  homelp: require('./assets/filled leftp.svg').default,
  privacy: require('./assets/description.svg').default,
  privacylp: require('./assets/description leftp.svg').default,
  generate: require('./assets/button.svg').default,
  hamburger: require('./assets/USSSA - Icons_Hamburger Menu.svg').default,
  crown: require('./assets/crown 1.svg').default,
  frame: require('./assets/vector.svg').default,
  proicon: require('./assets/pro.svg').default,
  upgradetopro: require('./assets/upgradetopro.png')  ,
  righticon: require('./assets/right.svg').default,
  Backbutton: require('./assets/arrow-left.svg').default,  
  Buybutton: require('./assets/buypremiumbutton.svg').default,
  book: require('./assets/book.svg').default, 




  //api endpoitns accross web
 // apiEndpoint: 'http://localhost/projectA/api',

 baseURL:"https://paragraphwriter.ai//Backend",

//baseURL:"http://localhost/",


  "FAQtitle": "How to Use Ai Paragraph Writer?",
  "points": [
    {
      "step": "Specify Topic:",
      "details": [
        "Begin by entering the topic of the paragraph you want to generate."
      ]
    },
    {
      "step": "Select Tone:",
      "details": [
        "Choose the desired tone (e.g., informal, informal, persuasive) for the paragraph."
      ]
    },
    {
      "step": "Select Length:",
      "details": [
        "Decide on the length of the paragraph (short, medium, long) based on your needs."
      ]
    },
    {
      "step": "Select Language: ",
      "details": [
        "Pick the language in which you want the paragraph to be written."
      ]
    },
    {
      "step": "Generate Paragraph: ",
      "details": [
        "Click on 'Write My Paragraph,' and the AI will generate a paragraph within 2-4 seconds."
      ]
    }
  ],



  //privacy page 
  "privacyTitle": "Privacy Policy for AI Paragraph Writer",
  "privacyEffectiveDate": "13-7-2023",
  "privacyPoints": [
    {
      "heading": "1. Information We Collect",
      "subsections": [
        {
          "heading": "1.1 Personal Information",
          "content": [
            "We may collect personal information that you provide directly to us when using the App. This includes:",
            "- Your name, email address, and other contact information when you sign up or contact us for support.",
            "- Story content and other user-generated content you provide within the App.",
            "- Communication and interaction details when you communicate with other users or us through the App."
          ]
        },
        {
          "heading": "1.2 Usage Information",
          "content": [
            "When you use the App, we may automatically collect certain information about your device and usage patterns, including:",
            "- Device information (such as model, operating system version, unique device identifiers).",
            "- Log information (such as IP address, access dates and times, and referring/exit pages).",
            "- App usage data, including the features you use, the pages you visit, and the actions you take."
          ]
        }
      ]
    },
    {
      "heading": "2. Use of Information",
      "subsections": [
        {
          "heading": "2.1 Provide and Improve the App",
          "content": [
            "We use the collected information to provide, maintain, and improve the functionality and performance of the App. This includes:",
            "- Personalizing your experience and providing you with tailored content.",
            "- Enhancing the App's security and detecting, preventing, and responding to potential fraud or security issues."
          ]
        }
      ]
    },
    {
      "heading": "3. Sharing Your Information",
      "content": [
        "- We do not share your personal information with third parties, except as required by law or with your explicit consent."
      ]
    }
  ]
};
